import * as React from 'react'
import '../app.css'
import styled from 'styled-components'
import ShowcaseSVG from '../components/showcaseSVG'
import bee from '../images/bee.svg'
import traffic from '../images/traffic-light.svg'
import brain from '../images/brain.svg'
import users from '../images/users.svg'
import manager from '../images/user-tie.svg'

const Wrapper = styled.main``

const Header = styled.div`
  background-color: #ffbe0b;
  width: 100%;
  min-height: 40vh;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  .headline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 67%;
    @media (max-width: 800px) {
      width: 100%;
      p {
        font-size: 0.6rem;
      }
    }
    text-align: center;
    h1 {
      margin: 2rem 0;
      font-size: 3rem;
      @media (max-width: 800px) {
        font-size: 2rem;
      }
      text-transform: uppercase;
    }
  }
  .cta {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #3a86ff;
      font-weight: bolder;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
    a {
      background-color: #fb5607;
      border-radius: 4px;
      padding: 0.4rem 0.6rem;
      width: 33%;
      cursor: pointer;
      text-decoration: none;
      color: inherit;
    }
  }
`
const Showcase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffbe0b;
  img {
    width: 50%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  background-image: linear-gradient(to bottom, transparent, #fefefe);
`
const Features = styled.div`
  width: 100%;
  min-height: 40vh;
  padding-bottom: 4rem;
  h2 {
    text-align: center;
    margin: 3rem 0;
    font-size: 2rem;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 1rem auto;
    width: 67%;
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      width: 100%;
    }

    li {
      margin: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        margin: 0.4rem;
      }
      p {
        text-align: center;
      }
      img {
        height: 2rem;
        width: 2rem;
        @media (max-width: 800px) {
          height: 1.5rem;
          width: 1.5rem;
        }
        color: white;
        border-radius: 4px;
        border: 3px solid #ffbe0b;
      }
    }
  }
`

const Examples = styled.div`
  margin: 4rem auto;
  width: 80%;
  padding-bottom: 4rem;
  h2 {
    text-align: center;
    font-size: 2rem;
  }
  span {
    text-transform: uppercase;
    color: gray;
    font-size: 0.8rem;
    margin: 0.4rem 0;
  }
  div {
    margin: 2rem 0;
  }
`

const Footer = styled.footer`
  width: 100%;
  height: 3rem;
  background-color: #fb5607;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    height: 6rem;
  }
`

// markup
const IndexPage = () => {
  return (
    <Wrapper>
      <title>Linkbee</title>
      <Header>
        <img src={bee} />
        <div className='headline'>
          <h1>Manage all your links in one place</h1>
          <p>
            With linkbee you can create dynamic links that sends your audience wherever you want, and change the
            destination without sending a new link.
          </p>
          <div className='cta'>
            <span>Get started with 1 linkbee link for free</span>
            <a href='https://omnifluence-app.web.app/sign-up/linkbee'>Create link</a>
          </div>
        </div>
      </Header>
      <Showcase>
        <ShowcaseSVG />
      </Showcase>
      <Features className='map-full'>
        <h2>Features</h2>
        <ul>
          <li>
            <img src={manager} />
            <h3>Link manager</h3>
            <p>Manage all your link destinations in one dashboard.</p>
          </li>
          <li>
            <img src={traffic} />
            <h3>Traffic router</h3>
            <p>
              Segment your link clicks depending on location, time of day or any other variable you want to filter by.
            </p>
          </li>
          <li>
            <img src={brain} />
            <h3>Smart links</h3>
            <p>Destinations can be set to automatically change based on numerous criteria.</p>
          </li>
          <li>
            <img src={users} />
            <h3>Google analytics integration</h3>
            <p>Linkbee works with google analytics making it easy to track your clicks.</p>
          </li>
        </ul>
      </Features>
      <Examples>
        <h2>Examples</h2>
        <div>
          <span>e-commerce</span>
          <h3>Time sensitive links</h3>
          <p>
            With linkbee you can redirect to specific pages depending on the time of day with the same link! A link to
            buying morning coffee can easily be a link to a afternoon coffee without any action needed on your end.
          </p>
        </div>
        <div>
          <span>marketing</span>
          <h3>Promotional links</h3>
          <p>
            Save time by auto updating promotional links once the promote changes. When you are running a promotional
            campaging you can easily update the destination link of that promition without having to update the link you
            send out with the campaign.
          </p>
        </div>
        <div>
          <span>affiliate</span>
          <h3>Auto uptade links</h3>
          <p>
            If you have affiliate links in old blog posts, if you used linkbee you won't need to go back and update them
            if the affiliale link changes. Simply log in to the linkbee dashboard and update the link there and it will
            update all your affliate links bound to that linkbee link.
          </p>
        </div>
      </Examples>
      <Footer>
        <p>
          Linkbee is a omnifluence product. For any inquiries please contact <code>hey@omnifluence.io</code>
        </p>
      </Footer>
    </Wrapper>
  )
}

export default IndexPage
